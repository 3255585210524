import { maxWidth, media } from "services/styled-components/variables";
import styled from "styled-components";

export const MenuSC = styled.nav`
    pointer-events: all;
    display: none;

    a {
        color: ${props => props.theme.textColor};
        transition: 0.3s ease 0s;

        &:hover {
            color: ${props => props.theme.activeColor};
        }
    }

    ${props => media.md`
        display: inline-block;
        vertical-align: middle;
        min-height: 73.5px;
        ul {
            font-size: 14px;
            text-transform: uppercase;
            max-width: ${maxWidth};
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            li {
                display: inline-block;
                overflow: hidden;
                transition: all 0.2s ease;
                a {
                    display: block;
                    white-space: nowrap;
                    padding: 2em 1.778em;
                    font-weight: 700;
                    &.active {
                        box-shadow: inset 0 -4px 0 0 ${props.theme.activeColor};
                    }
                }
            }
        }
    `}
    ${media.lg`
        font-size: 18px;
    `}
`;
