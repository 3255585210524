import { AndroidLogo } from "components/android-app/android-logo";
import { Access } from "components/check-roles/access";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { OnlyMobile } from "components/header-landing/components/menu/only-mobile-items/styled";
import {
    _1XBET_PARTNERS,
    BEATBET_PARTNERS,
    BETANDYOU_AFFILIATES,
    COINPLAY_PARTNERS,
    PARTNERS_1XBET,
} from "../../../../server/partner-programs";
import { Messengers } from "../../header-landing/components/social-network";
import { MessengersMenuSC } from "../../header-landing/styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const MenuLanding = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        androidAppLinks,
        visibility: { showingNews },
        partnersProgramId,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    const isCoinplayPartners = COINPLAY_PARTNERS === partnersProgramId;

    const documentTitle = isCoinplayPartners
        ? __("Руководство для партнеров")
        : __("Презентация");
    const documentName = isCoinplayPartners ? "manual" : "presentation";

    if (partnersProgramId === BEATBET_PARTNERS) {
        return (
            <>
                <ul role="navigation"></ul>
            </>
        );
    }

    return (
        <>
            <ul role="navigation">
                <li>
                    <LocalizedNavLinkWithOptionalBackground exact={true} to="/">
                        {__("Главная")}
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                {partnersProgramId === PARTNERS_1XBET && (
                    <li>
                        <a
                            href="https://blog.partners1xbet.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {__("Блог")}
                        </a>
                    </li>
                )}
                <Access name={documentName}>
                    <li>
                        <a
                            href={"/lapi/promotion/presentation.pdf"}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {documentTitle}
                        </a>
                    </li>
                </Access>
                {showingNews.showNews && (
                    <li>
                        {partnersProgramId === _1XBET_PARTNERS ? (
                            <a href="https://1xbetaffiliates.net/blog/">
                                {__("Новости")}
                            </a>
                        ) : (
                            <LocalizedNavLinkWithOptionalBackground
                                exact={true}
                                to="/news"
                            >
                                {__("Новости")}
                            </LocalizedNavLinkWithOptionalBackground>
                        )}
                    </li>
                )}
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/contacts"
                    >
                        {__("Контакты")}
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <Access name="faq">
                    <li>
                        <LocalizedNavLinkWithOptionalBackground
                            exact={true}
                            to="/faq"
                        >
                            {__("FAQ")}
                        </LocalizedNavLinkWithOptionalBackground>
                    </li>
                </Access>
                <li>
                    <OnlyMobile>
                        <LocalizedNavLinkWithOptionalBackground
                            exact={true}
                            to="/terms"
                        >
                            {__("Правила и условия")}
                        </LocalizedNavLinkWithOptionalBackground>
                    </OnlyMobile>
                </li>
                {androidAppLinks && (
                    <li>
                        <OnlyMobile>
                            <AndroidLogo size="22" link={androidAppLinks} />
                        </OnlyMobile>
                    </li>
                )}
            </ul>
            <OnlyMobile>
                {partnersProgramId === BETANDYOU_AFFILIATES && (
                    <MessengersMenuSC>
                        <Messengers inMobileMenu />
                    </MessengersMenuSC>
                )}
            </OnlyMobile>
        </>
    );
});
